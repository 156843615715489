<template>
  <div class="base-card" :style="cardStyle">
    <div class="card-head">
      <div class="card-head_left">
        <slot name="title"></slot>
      </div>
      <div class="card-head_right" v-if="rightButton">
        <el-button  size="mini" @click="detailInfo">查看 ></el-button>
      </div>
    </div>
    <div style="border:none;border-bottom:1px solid #DCDCDC"></div>
    <div class="card-content">
      <slot name="content"></slot>
    </div>
  </div>
</template>

<script>
export default {
  //style="width:100px;height:200px"
  name: 'BaseCard',
  methods:{
    detailInfo(){
      this.$emit('detailInfo')
    }
  },
  computed:{
    cardStyle(){
      return `width:${this.width}; height:${this.height}`
    }
  },
  props:{
    height:{
      type:[String,Number],
      default: "26.8vh"
    },
    width:{
      type:[String,Number],
      default: "600px"
    },
    rightButton:{
      type:Boolean,
      default:true
    },
    cardInfo:{
      type:Object,
      default(){
        return {}
      }
    }
  }
}
</script>

<style lang="scss" scoped>

.base-card{
  overflow: hidden;
  background: #FFFFFF;
  border-radius: 5px;
  box-sizing: border-box;
  margin-bottom: 20px;
  .card-head{
    display: flex;
    justify-content: space-between;
    height: 30px;
    margin: 10px;
    margin-bottom: 0px;
    box-sizing: border-box;
    background-color: #ffff;
    border-radius: 4px 4px 0px 0px;
    .card-head_left{
      font-weight: bold;
      font-size: 20px;
      display: flex;
    }
  }
  .card-content{
    margin: 20px;
  }
  button{
    padding: 5px 10px;
    border: none;
    font-size: 15px;
  }
}
</style>
